import React from "react"
import { Container, Heading, Box, Embed, Image } from "theme-ui"
import Logo from "../../images/logo-ab.jpg"
import { Helmet } from "react-helmet"

const PaginaInvito = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Invito di Angelo Baronchelli</title>
        <html lang="it" />
      </Helmet>
      <Box sx={{ minHeight: "100vh" }}>
        <Container
          sx={{
            pt: [3, 5],
            pb: [0, 5],
            px: [0, 0, 5, 5],
            maxWidth: ["100%", "960px"],
          }}
        >
          <Image
            src={Logo}
            alt="Logo AB"
            sx={{
              height: ["50px", "80px"],
              display: "block",
              margin: "0 auto",
            }}
          />
          <Heading
            as="h1"
            sx={{
              textAlign: "center",
              mb: [3, 5],
              mt: [3, 5],
              fontSize: [4, 5],
            }}
          >
            L'invito di Angelo Baronchelli, Presidente di AB
          </Heading>
          <Box
            sx={{
              padding: ["177% 0 0 0", "177% 0 0 0", "0 0 0 0", "0 0 0 0"],
              position: "relative",
              display: ["block", "block", "none", "none"],
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/1030643593?h=987cadbc91&badge=0&autopause=0&player_id=0&app_id=58479"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              frameBorder="0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Video Messagio ANGELO BARONCHELLI"
            />
          </Box>
          <Box
            sx={{
              padding: ["56.25% 0 0 0"],
              position: "relative",
              display: ["none", "none", "block", "block"],
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/1030646965?h=c4b4e1d9bf&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="Video Messaggio ANGELO BARONCHELLI"
            />
          </Box>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Container>
      </Box>
    </>
  )
}

export default PaginaInvito
